










































































































































































































































































































































import {
  LoadingStatusType,
  PeakPaymentConnectStatus,
  PeakPaymentStatus,
  ReceiptStatus,
  ShippingVatType,
  WhtAmountType,
} from "@/enums/enums";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

import AuthService from "@/service/authService";
const StorePackage = namespace("Package");
const auth = new AuthService();
@Component({})
export default class PackageDetail extends Vue {
  @StorePackage.Action
  private DoGetPeakPaymentConnectPackageInfo!: (input: any) => void;
  @StorePackage.Action
  private DoGetPeakPaymentConnectDocumentInfo!: (input: any) => void;
  @StorePackage.Getter
  private getPackageDetailLoadingStatus!: LoadingStatusType;
  @StorePackage.Getter
  private getPackageDetail!: any | null;
  @StorePackage.Getter
  private getPackageDocumentDetailLoadingStatus!: LoadingStatusType;
  @StorePackage.Getter
  private getPackageDocumentDetail!: any | null;
  packageDetail: any = null;
  packageDocumentDetail: any = null;
  PeakPaymentConnectStatus = PeakPaymentConnectStatus;
  ReceiptStatus = ReceiptStatus;
  PeakPaymentStatus = PeakPaymentStatus;
  isShowDocument = false;
  private id = "";
  fileList = [];

  created() {
    this.GetPackageDetail();
  }
  @Watch("getPackageDetailLoadingStatus", { immediate: true })
  getPackageDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      if (this.getPackageDetail) {
        this.packageDetail = this.getPackageDetail;
      }
    }
  }
  @Watch("getPackageDocumentDetailLoadingStatus", { immediate: true })
  getPackageDocumentDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      if (this.getPackageDocumentDetail) {
        this.packageDocumentDetail = this.getPackageDocumentDetail;
        this.fileList =
          this.packageDocumentDetail.receipt.files &&
          this.packageDocumentDetail.receipt.files.map((item: any) => {
            const files = {
              fileType: item.fileType.split("/"),
              fileName: item.fileName,
              fileFullUrl: `${process.env.VUE_APP_PeakBlobStorage}peakengine${item.fileUrl}`,
            };
            return files;
          });
      }
    }
  }
  public GetPackageDetail() {
    const input = {
      peakPaymentConnectV2Id: this.$route.query.id,
      merchantId: this.$route.query.merchantId,
    };
    this.DoGetPeakPaymentConnectPackageInfo(input);
  }
  public GetPackageDocumentDetail() {
    const input = {
      peakPaymentConnectV2Id: this.$route.query.id,
      receiptId: this.packageDetail.packageInfo.receiptId,
      merchantId: this.$route.query.merchantId,
    };
    this.DoGetPeakPaymentConnectDocumentInfo(input);
  }
  public GetDateTimeFormat(_date: string) {
    let newDate = new Date(_date);
    newDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
    let fullYear = newDate.getFullYear().toString();
    let month = (newDate.getMonth() + 1).toString();
    let date = newDate.getDate().toString();
    return `${date.length == 2 ? date : "0" + date}/${month.length == 2 ? month : "0" + month}/${fullYear}`;
  }
  public RedirectToPackageTable() {
    window.location.href = "/package";
  }
  public GetKeyByValue(enumValue: any, val: any): any {
    const key = Object.keys(enumValue).find((key) => enumValue[key] === val);
    return key;
  }
  public GetVatTypePercentage(vatType: number) {
    let vatTypeText = "";
    switch (vatType) {
      case ShippingVatType.None:
        vatTypeText = "None";
        break;
      case ShippingVatType.ZeroPercent:
        vatTypeText = "0%";
        break;
      case ShippingVatType.SevenPercent:
        vatTypeText = "7%";
        break;
    }
    return vatTypeText;
  }
  public GetWithheldList(whtType: number) {
    let whtAmountType = "";
    switch (whtType) {
      case WhtAmountType.NotSpecify:
        whtAmountType = "Not specify";
        break;
      case WhtAmountType.None:
        whtAmountType = "None";
        break;
      case WhtAmountType.Custom:
        whtAmountType = "Custom";
        break;
      case WhtAmountType.ZeroPointSevenFive:
        whtAmountType = "0.75%";
        break;
      case WhtAmountType.OnePercent:
        whtAmountType = "1%";
        break;
      case WhtAmountType.TwoPercent:
        whtAmountType = "2%";
        break;
      case WhtAmountType.ThreePercent:
        whtAmountType = "3%";
        break;
      case WhtAmountType.FivePercent:
        whtAmountType = "5%";
        break;
      case WhtAmountType.TenPercent:
        whtAmountType = "10%";
        break;
      case WhtAmountType.FifteenPercent:
        whtAmountType = "15%";
        break;
      case WhtAmountType.OnePointFivePercent:
        whtAmountType = "1.5%";
        break;
    }
    return whtAmountType;
  }
  public JoinTextList(itemList: any) {
    return itemList && itemList.length > 0 && itemList.map((item: { number: any }) => item.number).join(" , ");
  }
  public ToggleDocumentDetail() {
    this.isShowDocument = !this.isShowDocument;
    if (this.isShowDocument && !this.packageDocumentDetail) {
      this.GetPackageDocumentDetail();
    }
  }
}
